import React from "react";
import getApiUrl from "../api";

export default function UrlList(props) {
  const [urls, setUrls] = React.useState(null);
  const [newUrlInput, setNewUrlInput] = React.useState('');

  function checkUrls() {
    fetch(
        getApiUrl("CheckMonitoredSites"),
        {
            method: 'POST'
        })
        .then(response => {
            if (response.status === 200) {
                alert("All URLs checked");
            }
            else
            {
                response.text().then(function (text) {
                    alert(text);
                });
            }
        })
        .catch(error => console.error(error));
  }

  function getUrls() {
    fetch(getApiUrl("GetMonitoredSites"))
        .then(response => response.json())
        .then(data => {
            //console.log(data);
            setUrls(data);
        })
        .catch(error => console.error(error));
  }

  function addUrl(url) {
    fetch(
        getApiUrl("AddMonitoredSite"),
        {
            method: 'POST',
            headers: { 'Content-Type': 'text/plain' },
            body: url
        })
        .then(response => {
            if (response.status === 200) {
                getUrls();
                setNewUrlInput('');
            }
            else
            {
                response.text().then(function (text) {
                    alert(text);
                });
            }
        })
        .catch(error => console.error(error));
  }

  function deleteUrl(id) {
    fetch(
        getApiUrl("DeleteMonitoredSite/" + id),
        {
            method: 'DELETE'
        })
        .then(response => {
            if (response.status === 200) {
                getUrls();
            }
            else
            {
                response.text().then(function (text) {
                    alert(text);
                });
            }
        })
        .catch(error => console.error(error));
  }

  React.useEffect(() => {
      getUrls();
    }, []);

    if (urls == null)
    {
        return null;
    }

    return (
    <div>
        <table className="urls">
            <thead>
                <tr>
                    <th>Url</th>
                    <th>XPath</th>
                    <th>Dates</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {urls.map((url) => (
                <tr key={url.id}>
                    <td>
                        <a href={url.linkUrl ?? url.url} target="_blank" rel="noreferrer">{url.linkUrl ?? url.url}</a>
                        {url.linkUrl != null &&
                        <div>Fetch URL: {url.url}</div>
                        }
                    </td>
                    <td>{url.xPathToMonitor}</td>
                    <td>
                        <div>Last checked: {url.lastChecked ?? "Never"}</div>
                        <div>Last changed: {url.lastChanged ?? "Never"}</div>
                        <button onClick={() => props.setDetailsId(url.id)}>View content/diff</button>
                    </td>
                    <td><button onClick={()=> deleteUrl(url.id)}>Delete</button></td>
                </tr>
            ))}
            <tr>
                <td><input type="text" value={newUrlInput} onInput={e => setNewUrlInput(e.target.value)} placeholder="http://www.example.com" /></td>
                <td></td>
                <td></td>
                <td><button onClick={() => addUrl(newUrlInput)}>Add</button></td>
            </tr>
            </tbody>
        </table>
        <button onClick={checkUrls}>Check urls now</button>
        <p>
            <a href={getApiUrl("swagger/ui")} target="_blank" rel="noreferrer">View API Swagger documentation</a>
        </p>
    </div>
    );
}