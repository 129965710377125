import React from "react";
//import getApiUrl from "../api";

export default function Details(props) {
    //const [details, setDetails] = React.useState(null);
    const details = props.data;

    /*React.useEffect(() => {
        if (!details) return;
        const script = document.createElement('script');
        script.src = "https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js";
        script.async = false;
        //const placement = document.getElementById("details");
        const placement = document.body;
        placement.appendChild(script);
        // This crashes, I'm not sure why
        // I can't find script anywhere in the DOM but it does activate?!
        // Adding to index.html doesn't seem to work...?!
        return () => {
            placement.removeChild(script);
        }
    });*/

    function Content()
    {
        if (!details.diffs || details.diffs.length === 0)
        {
            try {
                const json = JSON.parse(details.content);
                if (json) {
                    return (<code className="prettyprint">{JSON.stringify(json, null, 2)}</code>);
                }
            } catch (e) {
            }

            return details.content;
        }
        else
        {
            return details.diffs.map(function(diff, i){
                switch (diff.operation)
                {
                    case 0:
                        return (<s style={{color: 'red'}}>{diff.text}</s>)
                    case 1:
                        return (<span style={{color: 'aqua'}}>{diff.text}</span>)
                    case 2:
                        return (<span>{diff.text}</span>)
                    default:
                }
                return (<p>Hello world</p>);
            });
        }
    }

    if (details == null)
    {
        return "Loading";
    }
    
    return (
        <div>
            <h1>{details.url}</h1>
            <p>Last checked: {details.lastChecked}</p>
            <p>Last changed: {details.lastChanged}</p>
            <h2>Content</h2>
            <pre>
                <Content />
            </pre>
        </div>
    )
}