import React from 'react'
import logo from './logo.svg';
import './App.css';
import UrlList from './components/UrlList'
import Details from './components/Details'
import getApiUrl from "./api";

export default function App() {
  const [userData, setUserData] = React.useState([]);
  const [loading, setIsLoading] = React.useState(true);
  const [detailsId, setDetailsId] = React.useState(null);
  const [details, setDetails] = React.useState(null);

  /*const setDetailsId = (id) => {
    alert(id);
  }*/

  React.useEffect(() => {
    fetch(".auth/me")
      .then(response => response.json())
      .then(data => {
          //console.log(data);
          const clientPrincipal = data?.clientPrincipal;
          const loggedIn = clientPrincipal !== null;
          //console.log("Logged in:", loggedIn);

          if (loggedIn)
          {
            const isAdministrator = clientPrincipal.userRoles.includes("administrator");
            setUserData({
              loggedIn,
              "userDetails": clientPrincipal.userDetails,
              isAdministrator
            });

            if (isAdministrator)
            {
              fetch(process.env.PUBLIC_URL + "/secrets/secrets.json")
                  .then(response => response.json())
                  .then(data => {
                    window.secrets = data;
                    setIsLoading(false);
                  })
                  .catch(error => console.error(error));
            }
            else setIsLoading(false);
          }
          else setIsLoading(false);
      })
      .catch(_ => console.error("Failed loading .auth/me. This is expected unless app is hosted in Azure or Azure emulator."));
  }, []);

  React.useEffect(() => {
    if (detailsId != null)
    { 
        fetch(getApiUrl("/GetMonitoredSite/" + detailsId),
        {
            method: 'GET'
        })
        .then(response => response.json())
        .then(data => setDetails(data))
        .catch(error => console.error(error));
    }
    else setDetails(null);
  }, [detailsId]);

  React.useEffect(() => {
    if (details == null) return;
    openDialog();
  }, [details]);

  function setDetailsIdFromList(id)
  {
    if (id === detailsId) openDialog();
    else setDetailsId(id);
  }

  function openDialog()
  {
    const dialog = document.querySelector("dialog");
    dialog.showModal();
  }

  function closeDialog()
  {
    document.querySelector("dialog")?.close();
  }

  console.log("Rendering...");
  //console.log(userData);

  if (loading)
  {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Loading...</p>
        </header>
      </div>
    );
  }

  if (!userData.loggedIn)
  {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <a className="App-link" href="/login">Log in</a>
        </header>
      </div>
    );
  }

  if (!userData.isAdministrator)
  {
    return (
      <div className="App">
        <p>Hello {userData.userDetails}!</p>
        <p>You need to be a part of the group "administrator" in order to use this site.</p>
        <a href="/logout">Log out</a>
      </div>
    );
  }

  return (
    <div className="App">
      <p>Hello {userData.userDetails}!</p>
      <UrlList setDetailsId={setDetailsIdFromList} />
      <dialog id="details">
        <button onClick={() => closeDialog()} style={{float: "right"}}>X</button>
        <Details data={details} />
      </dialog>
      <a href="/logout">Log out</a>
    </div>
  );
}